 export const environment = {
    production: false,
    appName: 'Finghway',
     home: '/painel',
    api: 'https://api-dev-finghway.officecom.app/api',
    url: 'https://dev-finghway.officecom.app/',
    // version: require('../../package.json').version,

   passwordRules: {
         minlength: 8,
         minLowercaseCharacterRule: 1,
         minUppercaseCharacterRule: 1,
         minDigitCharacterRule: 1,
         minSpecialCharacterRule: 1
     },

    googleClientId: '913733819215-36qje6q6nqju7c51c1200dmf9ilmjop6.apps.googleusercontent.com',
    googleSecretKey: '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',
    googleRecaptchaSiteKey: '6LdyljAqAAAAAC8aDnZkn03mcP3ESGUnyAnzScjs',
    googleRecaptchaSecretKey: '6LdyljAqAAAAADjI4VcZUc7cZFQv2BVuzNHg2PM6',

    socket: {
      key: "mNKxhCrvOIDjXjfSP3mAVWhm9CBqe3Th",
      wsHost: "socket-dev-finghway.officecom.app",
      wsPort: 443,
      wssHost: "socket-dev-finghway.officecom.app",
      wssPort: 443,
      forceTLS: true,
    }
   };
